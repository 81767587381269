import type { Product } from '~/types/product'
import type { CategoryFacet, Facet, ParsedSearchResponse } from '~/types/bloomreachDiscoveryApi'
import loadPrettyUrlFilters from '~/stores/usePrettyUrlStore/helpers/loadPrettyUrlFilters'
import getFiltersFromRoutePath from './helpers/getFiltersFromRoutePath'
import setFilterPath from './helpers/setFilterPath'
import type { CategoryList } from '~/stores/useCategoryStore/categoryStore'
import { useRoute } from 'vue-router'

export const facetIds = [
  'original_price',
  'price',
  'colors',
  'sizes',
  'campaign',
  'fit',
  'material',
  'season',
  'style_profile',
  'trends',
  'necklace_length',
  'productgroup_old',
]
export const nonPrettyUrlFacets = ['price', 'productgroup_old']
const facetsPreferredOrder = ['price', 'colors', 'sku_color', 'sizes']
const facetsToIgnore = ['category', 'brand', 'sale', 'letter', 'original_price', 'sale_price', 'phone_type']
const facetsKeysMap = {
  sale_price: 'price',
}
export const doSearchOperations = {
  PREPEND: 'PREPEND',
  APPEND: 'APPEND',
}

export const useBloomreachDiscoveryStore = defineStore('bloomreachDiscovery', () => {
  const {
    $i18n: { t, locale },
  } = useNuxtApp()
  const logger = useAppLogger('useBloomreachDiscoveryStore')
  const { routeData } = storeToRefs(usePageStore())
  const { closeFilterSidebar } = useUiState()
  const route = useRoute()
  const config = useRuntimeConfig()
  const { storeUrl } = config.public

  const { getCategoryList } = useCategoryStore()

  const breadcrumbsStore = useBreadcrumbsStore()
  const { loading: breadcrumbsLoading } = storeToRefs(breadcrumbsStore)
  const { generatePCPBreadcrumbs } = breadcrumbsStore

  const state = reactive({
    loading: <boolean>false,
    searchResponse: <ParsedSearchResponse | null>null,
    selectedFilters: <Record<string, any>>{},
    appliedFilters: <Record<string, any>>{},
    selectedSortOption: <string>route.query?.product_list_order?.toString() || '',
    itemsPerPage: <number>28,
    prependedPage: <number>1,
    currentPage: <number>1,
    products: <Product[]>[],
    productsAmount: <number>0,
    responsePage: <number>1,
    previousPage: <number>1,
    campaigns: <Record<string, any>>{},
    selectedPrettyUrls: [],
    subcategories: <Record<string, any>[]>[],
  })

  const productsInSearchResponse = computed(() => (state.searchResponse?.response?.docs as Product[]) || [])

  const sortOptions = computed(() => {
    return [
      {
        name: t('Recommended'),
        value: '',
      },
      {
        name: t('Newest'),
        value: 'newest',
      },
      {
        name: t('Lowest price'),
        value: 'price',
      },
      {
        name: t('Highest price'),
        value: 'price_high',
      },
      {
        name: t('Name A-Z'),
        value: 'name',
      },
    ]
  })
  const formatAndSortFacetOptions = (facet: Facet) => {
    if (facet.type === 'number_range') {
      return (
        facet.value?.map((value) => ({
          ...value,
          label: value.end,
        })) || []
      )
    }
    if (facet.name === 'month' || facet.name === 'month_flower') {
      const formatter = new Intl.DateTimeFormat(locale.value, { month: 'long' })
      const months = Array.from(Array(12).keys()).map((k) => formatter.format(new Date(1970, k, 1)))
      return (
        facet.value
          ?.sort((a, b) => months.indexOf(a?.name) - months.indexOf(b?.name))
          ?.map((o) => ({
            ...o,
            label: o.name?.charAt(0).toUpperCase() + o.name?.slice(1),
          })) || []
      )
    } else {
      return (
        facet.value?.map((o) => ({
          ...o,
          label: o.name,
        })) || []
      )
    }
  }

  const facets = computed(() => {
    return state.searchResponse?.facet_counts?.facets
      ?.filter((facet) => !facetsToIgnore.includes(facet.name))
      .sort(
        (a, b) =>
          (facetsPreferredOrder.indexOf(a.name) > -1
            ? facetsPreferredOrder.indexOf(a.name)
            : facetsPreferredOrder.length) -
          (facetsPreferredOrder.indexOf(b.name) > -1
            ? facetsPreferredOrder.indexOf(b.name)
            : facetsPreferredOrder.length),
      )
      .map((facet) => {
        return {
          id: facet.name,
          label: t((facet.name[0].toUpperCase() + facet.name.slice(1)).replace('_', ' ').replace('Sizes', 'Size')),
          type: facet.type,
          options: formatAndSortFacetOptions(facet),
        }
      })
      .filter((f) => (Array.isArray(f.options) ? f.options.length : f.options))
  })

  const pagination = computed(() => ({
    currentPage: state.currentPage,
    prependedPage: state.prependedPage,
    totalProducts: state.productsAmount || 0,
    currentProducts: state.products?.length || 0,
    itemsPerPage: state.itemsPerPage,
    totalPages: Math.ceil(state.productsAmount / state.itemsPerPage) || 1,
  }))

  const getCampaign = async (query: string, categoryId: number, url: string) => {
    if (
      !['CATEGORY', 'SEARCH'].includes(routeData.value.type) ||
      (!categoryId && !query) ||
      state.campaigns[categoryId || query] !== undefined
    )
      return

    const { campaign } = await $fetch('/api/bloomreach/discovery', {
      query: {
        search_type: categoryId ? 'category' : 'keyword',
        q: query,
        rows: 0,
        start: 1,
        sort: '',
        fq: {},
        url,
        locale: locale.value,
        has_pretty_filters: route?.meta?.routeData.value?.has_pretty_filters,
      },
    })

    if (campaign) {
      state.campaigns[query] = campaign
    } else {
      state.campaigns[query] = false
    }
  }

  const setSubcategories = async (routeData): Promise<{ categoryList: CategoryList; queryCategoryId: number }> => {
    const facets = state.searchResponse?.facet_counts.facets
    let queryCategoryId = routeData.value.id

    let categoryList: CategoryList = await getCategoryList({
      parent_id: { in: [queryCategoryId] },
    })

    // Current category might not have items in category list
    // But parent should at least have the current category. Hence try with parentId
    if (!categoryList.length) {
      const categoryFacet = facets?.find((facet) => facet.name === 'category') as CategoryFacet

      if (!categoryFacet.value.length) {
        logger.warn('categoryFacet is empty')
        return {}
      }

      const { parent: parentId } = categoryFacet?.value?.find((c) => c.cat_id === routeData.value.id?.toString()) || {}
      queryCategoryId = parentId

      categoryList = await getCategoryList({
        parent_id: { in: [queryCategoryId] },
      })
    }

    if (!categoryList.length) {
      logger.warn('No category list found')
      return {}
    }

    state.subcategories = categoryList.filter((category) => category?.is_anchor && category.product_count! > 0) || []

    return { categoryList, queryCategoryId }
  }

  const parseSearchResponse = (page) => {
    if (!state.searchResponse.response?.numFound) {
      if (routeData.value.type === 'SEARCH') {
        logger.warn('No result for search: ' + route.fullPath)
      } else if (routeData.value.query || routeData.value.has_pretty_filters) {
        logger.warn('No products for page with filters: ' + route.fullPath)
      } else {
        logger.warn('No products for page: ' + route.fullPath)
      }
    }

    if (state.searchResponse.response?.numFound) {
      state.searchResponse.response.docs.forEach((product) => (product.page = page))
    }

    if (state.searchResponse?.keywordRedirect) {
      const url = state.searchResponse?.keywordRedirect['redirected url']
      const isExternal = !url.includes(config.public.storeUrl)
      navigateTo(!url.includes('http') ? `https://${url}` : url, { external: isExternal })
    }

    return state.searchResponse?.response?.start / state.itemsPerPage + 1 || 0
  }

  // Mutations
  const doSearch = async ({ page = state.currentPage, setProducts = false }): Promise<boolean> => {
    const { loadPcpUsps } = usePCPStore()

    if (setProducts) {
      state.loading = true

      clearFilters()
      await loadPrettyUrlFilters()

      getFiltersFromRoutePath()

      state.currentPage = state.prependedPage = parseInt(page) || 1
    }

    const query = route?.query?.q || routeData.value.query?.q
    state.previousPage = state.searchResponse?.response?.start / state.itemsPerPage + 1 || 1

    if (JSON.stringify(state.appliedFilters) !== JSON.stringify(state.selectedFilters)) {
      state.currentPage = 1
    }

    const categoryId = routeData.value?.id?.toString()

    await getCampaign(categoryId || query, categoryId, `${storeUrl}${route.path}`)

    state.itemsPerPage = state.campaigns[categoryId || query] ? 27 : 28

    state.searchResponse = await $fetch('/api/bloomreach/discovery', {
      query: {
        req_url: route.fullPath,
        search_type: query ? 'keyword' : 'category',
        q: query || routeData.value?.id?.toString(),
        rows: state.itemsPerPage,
        start: (page - 1) * state.itemsPerPage,
        sort: state.selectedSortOption || '',
        fq: state.selectedFilters,
        url: `${storeUrl}/${routeData.value.relative_url}`,
        locale: locale.value,
        has_pretty_filters: route?.meta?.routeData.value?.has_pretty_filters,
        utm_campaign: route.query?.utm_campaign,
      },
    })

    state.responsePage = parseSearchResponse(page)

    if (setProducts) {
      state.products = productsInSearchResponse.value
      state.productsAmount = state.searchResponse?.response?.numFound ?? 0
    }

    if (routeData.value.type === 'CATEGORY') {
      breadcrumbsLoading.value = true
      const { categoryList, queryCategoryId } = await setSubcategories(routeData)
      generatePCPBreadcrumbs(categoryList, queryCategoryId)
      breadcrumbsLoading.value = false

      await loadPcpUsps()
    }

    state.loading = false
    return true
  }

  const applyFilters = async (moreProducts: string | null = null) => {
    closeFilterSidebar()

    state.appliedFilters = JSON.parse(JSON.stringify(state.selectedFilters))

    if (moreProducts === doSearchOperations.APPEND) {
      state.products = [...state.products, ...productsInSearchResponse.value]
    } else if (moreProducts === doSearchOperations.PREPEND) {
      state.products = [...productsInSearchResponse.value, ...state.products]
    }

    state.productsAmount = state.searchResponse?.response?.numFound ?? 0

    state.previousPage = state.responsePage
    await setFilterPath(moreProducts)
  }

  const clearFilters = () => {
    state.appliedFilters = {}
    state.selectedFilters = {}
  }

  return {
    ...toRefs(state),
    facetsKeysMap,
    sortOptions,
    facets,
    pagination,
    doSearch,
    applyFilters,
    clearFilters,
  }
})

export default useBloomreachDiscoveryStore
