import { checkElement } from '~/utils/checkElement'

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const trustbadgeId = 'minimized-trustbadge-98e3dadd90eb493088abdc5597a70810'

  const checkTrustedBadge = async () => {
    const timeout = new Promise((_, reject) => setTimeout(() => reject(new Error('Trustbadge check timeout')), 5000))

    const check = new Promise(async (resolve) => {
      while (!window.trustbadge || typeof window.trustbadge?.reInitialize !== 'function') {
        await new Promise((res) => requestAnimationFrame(res))
      }
      resolve(true)
    })

    return Promise.race([check, timeout])
  }

  const removeTrustedBadgeScript = () => {
    const script = document.querySelector('script[src*="trustedshops"]')
    script?.remove()

    const widget = document.getElementById(trustbadgeId)
    widget?.remove()

    if (window.trustbadge) {
      delete window.trustbadge
    }

    if (window._tsConfig) {
      window._tsConfig.disableTrustbadge = 'true'
    }
  }

  const addCloseButton = async () => {
    try {
      const widget = await checkElement(`#${trustbadgeId}`)
      if (widget.querySelector('.trustbadge-close-btn')) return

      const closeButton = document.createElement('button')
      closeButton.innerText = '×'
      closeButton.classList.add('trustbadge-close-btn')

      Object.assign(closeButton.style, {
        position: 'absolute',
        top: '5px',
        left: '5px',
        background: 'white',
        color: 'black',
        border: 'none',
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        fontSize: '16px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '9999',
      })

      closeButton.addEventListener('click', removeTrustedBadgeScript)

      widget.style.position = 'relative'
      widget.appendChild(closeButton)
    } catch (error) {
      console.warn('Failed to find widget for close button:', error.message)
    }
  }

  const initializeTrustbadge = async () => {
    removeTrustedBadgeScript()

    try {
      await checkTrustedBadge()

      if (window._tsConfig) {
        window._tsConfig.disableTrustbadge = 'false'
      }

      window.trustbadge.reInitialize()
      addCloseButton()
    } catch (error) {
      console.error('Error initializing trustbadge:', error.message)
    }
  }

  const toggleWidget = () => {
    const widget = document.getElementById(trustbadgeId)
    if (!widget) return

    const path = router.currentRoute.value.path
    widget.style.display = path.includes('/checkout') ? 'none' : 'block'
  }

  router.afterEach(() => {
    toggleWidget()
  })

  initializeTrustbadge()
  toggleWidget()
})
