import type { AppConfigInput } from '@nuxt/schema'

export default {
  select: {
    slots: {
      root: 'w-full h-[3rem]',
      base: 'h-[3rem] rounded-(--ui-radius) text-size[16px] md:text-size[18px]',
      trailingIcon: 'iconify i-ph:caret-down transition-transform duration-200 group-data-[state=open]:rotate-180',
      content: `-mt-[10px]
        -mb-[10px]
        rounded-t-none
        rounded-b-(--ui-radius)
        ring-0
        shadow-none
        border
        border-[var(--grey-secondary)]
        data-[side=bottom]:border-t-none
        data-[side=top]:border-b-none
        data-[side=top]:rounded-t-(--ui-radius)
        data-[side=top]:rounded-b-none
        after:content-[""]
        after:fixed
        after:left-[1px]
        after:h-[1px]
        after:w-[calc(100%-2px)]
        after:bg-[var(--grey-background)]
        data-[side=top]:after:-bottom-[10px]
        data-[side=bottom]:after:-top-[10px]
        before:content-[""]
        before:absolute
        data-[side=bottom]:before:top-[-56px]
        data-[side=top]:before:bottom-[-56px]
        before:left-0
        before:right-0
        data-[side=bottom]:before:bottom-[-10px]
        data-[side=top]:before:top-[-10px]
        before:rounded-[2px]
        before:shadow-lg`,
      item: 'hover:bg-[var(--grey-background)] p-3! data-[state=checked]:font-bold',
      group: 'p-0',
      itemTrailing: 'hidden',
    },
    compoundVariants: [
      {
        color: 'primary',
        variant: 'outline',
        class: 'focus-visible:ring-1',
      },
    ],
    variants: {
      size: {
        xs: { base: 'text-[16px]' },
        sm: { base: 'text-[16px]' },
        md: { base: 'text-[16px]' },
        lg: { base: 'text-[16px]' },
        xl: { base: 'text-base' },
      },
    },
  },
} as AppConfigInput['ui']
