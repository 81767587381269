
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indextrc7up_45m9H1D5bwEWLkhIaMQywpN5I_45u6P72X4hoy98Meta } from "/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/index.vue?macro=true";
import { default as Edit8HpbubFL_Cio2SInkKkxxQVt5lK1qdfJ96B56guRQ4AMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/Edit.vue?macro=true";
import { default as indexaclafrZ91braMzMzsoEmmJ1m5F2IcxzKX_nsJp3Yld8Meta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/index.vue?macro=true";
import { default as Addressrv8U5YcficjDSkX3n0pB6O8y71HEg8i9gsMV83RZlBYMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Address.vue?macro=true";
import { default as InfoKsXuJ8kc3n4sAQGOCIFwT1cc06bXYK8kBR8cQIoVKOoMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Info.vue?macro=true";
import { default as HistorywwupnV60eG1bO4MOQuPBvCRXnlhCVO2Kut_45uhToiL1IMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/sales/order/History.vue?macro=true";
import { default as WishlistuLbOZRvZFFkfrmhXQsrnWeWxJur68X_45YGKNQdYb5SSoMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/Wishlist.vue?macro=true";
import { default as createPHsmJjnurcSgAAfIElIoURj7_45vPMO_45e5vDZLJmRRN9QMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/create.vue?macro=true";
import { default as createpasswordSEKx1sUjzELVrvZ5ggC4wf_45AWBAOIB3LwMauSf_453ivsMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/createpassword.vue?macro=true";
import { default as forgotpasswordXQtpX3IwhkQ500jRb7q48bp7IydnubETNh0USSGoLm8Meta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/forgotpassword.vue?macro=true";
import { default as login_zStJpbfR5a20I5zyWWs7820tJwlyDjJ5gx7Jw_fJHAMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/login.vue?macro=true";
import { default as _91_46_46_46slug_93rwyC9CknejlvEYHz4H2yTwPibJf25rVYidQ7w9MrMTwMeta } from "/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46ean_93XHoC2KkYgR7VSUL_FwvNf7GHEJH1vXYxAIGl3UJfr_45oMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/01/[...ean].vue?macro=true";
import { default as blogpostfZo0yu99PFsdNMcCWT4CX473B3iGaQTp4tVTZWvnue0Meta } from "/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue?macro=true";
import { default as resultsGF6rGjY9eZQhzptcUr1l09aZgAdjqSTnU5xtS13QqsMeta } from "/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/catalogsearch/result.vue?macro=true";
import { default as customerserviceDkyTpw0nRR_RwINtu5S_JP5H8MU8FfM8MsqbH78X6wEMeta } from "/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue?macro=true";
import { default as shops9VQmFY8BsmGAJsUqSUaOvrYjZmQcSnYC79fQHMPCJZEMeta } from "/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue?macro=true";
import { default as exponeaMVQlkrj6qDV8Eedg4XB80wfOpo4OGHADKQ6xa3trUYAMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/utils/exponea.vue?macro=true";
import { default as index2Qv2SKC7QpMOm93wieclGnf7hRyUZVJGDpHtde41H5QMeta } from "/builds/my-jewellery/code/frontend-nuxt3/pages/utils/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: { ...(indextrc7up_45m9H1D5bwEWLkhIaMQywpN5I_45u6P72X4hoy98Meta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/index.vue")
  },
  {
    name: "customer-account-Edit___nl-nl",
    path: "/nl-nl/customer/account/Edit",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/Edit.vue")
  },
  {
    name: "customer-account-Edit___de",
    path: "/de/customer/account/Edit",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/Edit.vue")
  },
  {
    name: "customer-account-Edit___en",
    path: "/en/customer/account/Edit",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/Edit.vue")
  },
  {
    name: "customer-account-Edit___fr",
    path: "/fr/customer/account/Edit",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/Edit.vue")
  },
  {
    name: "customer-account___nl-nl",
    path: "/nl-nl/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/index.vue")
  },
  {
    name: "customer-account___de",
    path: "/de/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/index.vue")
  },
  {
    name: "customer-account___en",
    path: "/en/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/index.vue")
  },
  {
    name: "customer-account___fr",
    path: "/fr/customer/account",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/account/index.vue")
  },
  {
    name: "customer-Address___nl-nl",
    path: "/nl-nl/customer/Address",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Address.vue")
  },
  {
    name: "customer-Address___de",
    path: "/de/customer/Address",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Address.vue")
  },
  {
    name: "customer-Address___en",
    path: "/en/customer/Address",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Address.vue")
  },
  {
    name: "customer-Address___fr",
    path: "/fr/customer/Address",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Address.vue")
  },
  {
    name: "customer-Info___nl-nl",
    path: "/nl-nl/customer/Info",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Info.vue")
  },
  {
    name: "customer-Info___de",
    path: "/de/customer/Info",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Info.vue")
  },
  {
    name: "customer-Info___en",
    path: "/en/customer/Info",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Info.vue")
  },
  {
    name: "customer-Info___fr",
    path: "/fr/customer/Info",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/customer/Info.vue")
  },
  {
    name: "sales-order-History___nl-nl",
    path: "/nl-nl/sales/order/History",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/sales/order/History.vue")
  },
  {
    name: "sales-order-History___de",
    path: "/de/sales/order/History",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/sales/order/History.vue")
  },
  {
    name: "sales-order-History___en",
    path: "/en/sales/order/History",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/sales/order/History.vue")
  },
  {
    name: "sales-order-History___fr",
    path: "/fr/sales/order/History",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/sales/order/History.vue")
  },
  {
    name: "Wishlist___nl-nl",
    path: "/nl-nl/Wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/Wishlist.vue")
  },
  {
    name: "Wishlist___de",
    path: "/de/Wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/Wishlist.vue")
  },
  {
    name: "Wishlist___en",
    path: "/en/Wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/Wishlist.vue")
  },
  {
    name: "Wishlist___fr",
    path: "/fr/Wishlist",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(account)/Wishlist.vue")
  },
  {
    name: "customer-account-create___nl-nl",
    path: "/nl-nl/customer/account/create",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/create.vue")
  },
  {
    name: "customer-account-create___de",
    path: "/de/customer/account/create",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/create.vue")
  },
  {
    name: "customer-account-create___en",
    path: "/en/customer/account/create",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/create.vue")
  },
  {
    name: "customer-account-create___fr",
    path: "/fr/customer/account/create",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/create.vue")
  },
  {
    name: "customer-account-createpassword___nl-nl",
    path: "/nl-nl/customer/account/createpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/createpassword.vue")
  },
  {
    name: "customer-account-createpassword___de",
    path: "/de/customer/account/createpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/createpassword.vue")
  },
  {
    name: "customer-account-createpassword___en",
    path: "/en/customer/account/createpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/createpassword.vue")
  },
  {
    name: "customer-account-createpassword___fr",
    path: "/fr/customer/account/createpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/createpassword.vue")
  },
  {
    name: "customer-account-forgotpassword___nl-nl",
    path: "/nl-nl/customer/account/forgotpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/forgotpassword.vue")
  },
  {
    name: "customer-account-forgotpassword___de",
    path: "/de/customer/account/forgotpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/forgotpassword.vue")
  },
  {
    name: "customer-account-forgotpassword___en",
    path: "/en/customer/account/forgotpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/forgotpassword.vue")
  },
  {
    name: "customer-account-forgotpassword___fr",
    path: "/fr/customer/account/forgotpassword",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/forgotpassword.vue")
  },
  {
    name: "customer-account-login___nl-nl",
    path: "/nl-nl/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/login.vue")
  },
  {
    name: "customer-account-login___de",
    path: "/de/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/login.vue")
  },
  {
    name: "customer-account-login___en",
    path: "/en/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/login.vue")
  },
  {
    name: "customer-account-login___fr",
    path: "/fr/customer/account/login",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/(login)/customer/account/login.vue")
  },
  {
    name: "slug___nl-nl",
    path: "/nl-nl/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93rwyC9CknejlvEYHz4H2yTwPibJf25rVYidQ7w9MrMTwMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93rwyC9CknejlvEYHz4H2yTwPibJf25rVYidQ7w9MrMTwMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93rwyC9CknejlvEYHz4H2yTwPibJf25rVYidQ7w9MrMTwMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    meta: { ...(_91_46_46_46slug_93rwyC9CknejlvEYHz4H2yTwPibJf25rVYidQ7w9MrMTwMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/[...slug].vue")
  },
  {
    name: "01-ean___nl-nl",
    path: "/nl-nl/01/:ean(.*)*",
    meta: {"middleware":[]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/01/[...ean].vue")
  },
  {
    name: "01-ean___de",
    path: "/de/01/:ean(.*)*",
    meta: {"middleware":[]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/01/[...ean].vue")
  },
  {
    name: "01-ean___en",
    path: "/en/01/:ean(.*)*",
    meta: {"middleware":[]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/01/[...ean].vue")
  },
  {
    name: "01-ean___fr",
    path: "/fr/01/:ean(.*)*",
    meta: {"middleware":[]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/01/[...ean].vue")
  },
  {
    name: "blogpost___nl-nl",
    path: "/nl-nl/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "blogpost___de",
    path: "/de/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "blogpost___en",
    path: "/en/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "blogpost___fr",
    path: "/fr/blogpost",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "catalogsearch-result___nl-nl",
    path: "/nl-nl/catalogsearch/result",
    meta: { ...(resultsGF6rGjY9eZQhzptcUr1l09aZgAdjqSTnU5xtS13QqsMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/catalogsearch/result.vue")
  },
  {
    name: "catalogsearch-result___de",
    path: "/de/catalogsearch/result",
    meta: { ...(resultsGF6rGjY9eZQhzptcUr1l09aZgAdjqSTnU5xtS13QqsMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/catalogsearch/result.vue")
  },
  {
    name: "catalogsearch-result___en",
    path: "/en/catalogsearch/result",
    meta: { ...(resultsGF6rGjY9eZQhzptcUr1l09aZgAdjqSTnU5xtS13QqsMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/catalogsearch/result.vue")
  },
  {
    name: "catalogsearch-result___fr",
    path: "/fr/catalogsearch/result",
    meta: { ...(resultsGF6rGjY9eZQhzptcUr1l09aZgAdjqSTnU5xtS13QqsMeta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/catalogsearch/result.vue")
  },
  {
    name: "customerservice___nl-nl",
    path: "/nl-nl/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "customerservice___de",
    path: "/de/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "customerservice___en",
    path: "/en/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "customerservice___fr",
    path: "/fr/customerservice",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "index___nl-nl",
    path: "/nl-nl",
    meta: { ...(indextrc7up_45m9H1D5bwEWLkhIaMQywpN5I_45u6P72X4hoy98Meta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: { ...(indextrc7up_45m9H1D5bwEWLkhIaMQywpN5I_45u6P72X4hoy98Meta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: { ...(indextrc7up_45m9H1D5bwEWLkhIaMQywpN5I_45u6P72X4hoy98Meta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: { ...(indextrc7up_45m9H1D5bwEWLkhIaMQywpN5I_45u6P72X4hoy98Meta || {}), ...{"middleware":["bloomreach"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/index.vue")
  },
  {
    name: "shops___nl-nl",
    path: "/nl-nl/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "shops___de",
    path: "/de/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "shops___en",
    path: "/en/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "shops___fr",
    path: "/fr/shops",
    meta: {"middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "utils-exponea___nl-nl",
    path: "/nl-nl/utils/exponea",
    meta: { ...(exponeaMVQlkrj6qDV8Eedg4XB80wfOpo4OGHADKQ6xa3trUYAMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/exponea.vue")
  },
  {
    name: "utils-exponea___de",
    path: "/de/utils/exponea",
    meta: { ...(exponeaMVQlkrj6qDV8Eedg4XB80wfOpo4OGHADKQ6xa3trUYAMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/exponea.vue")
  },
  {
    name: "utils-exponea___en",
    path: "/en/utils/exponea",
    meta: { ...(exponeaMVQlkrj6qDV8Eedg4XB80wfOpo4OGHADKQ6xa3trUYAMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/exponea.vue")
  },
  {
    name: "utils-exponea___fr",
    path: "/fr/utils/exponea",
    meta: { ...(exponeaMVQlkrj6qDV8Eedg4XB80wfOpo4OGHADKQ6xa3trUYAMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/exponea.vue")
  },
  {
    name: "utils___nl-nl",
    path: "/nl-nl/utils",
    meta: { ...(index2Qv2SKC7QpMOm93wieclGnf7hRyUZVJGDpHtde41H5QMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/index.vue")
  },
  {
    name: "utils___de",
    path: "/de/utils",
    meta: { ...(index2Qv2SKC7QpMOm93wieclGnf7hRyUZVJGDpHtde41H5QMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/index.vue")
  },
  {
    name: "utils___en",
    path: "/en/utils",
    meta: { ...(index2Qv2SKC7QpMOm93wieclGnf7hRyUZVJGDpHtde41H5QMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/index.vue")
  },
  {
    name: "utils___fr",
    path: "/fr/utils",
    meta: { ...(index2Qv2SKC7QpMOm93wieclGnf7hRyUZVJGDpHtde41H5QMeta || {}), ...{"middleware":["utils"]} },
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/pages/utils/index.vue")
  },
  {
    name: "blog___nl-nl",
    path: "/nl-nl/blog/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "blog___fr",
    path: "/fr/blog/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/blogpost.vue")
  },
  {
    name: "customerservice___nl-nl",
    path: "/nl-nl/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "customerservice___de",
    path: "/de/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "customerservice___en",
    path: "/en/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "customerservice___fr",
    path: "/fr/:service(kundenservice|klantenservice|service-client|customer-service)/:slug*",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/customerservice.vue")
  },
  {
    name: "shops___nl-nl",
    path: "/nl-nl/:service(boutiques|boutiquen)/:slug+",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "shops___de",
    path: "/de/:service(boutiques|boutiquen)/:slug+",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "shops___en",
    path: "/en/:service(boutiques|boutiquen)/:slug+",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  },
  {
    name: "shops___fr",
    path: "/fr/:service(boutiques|boutiquen)/:slug+",
    meta: {"layout":"storefront","middleware":["bloomreach"]},
    component: () => import("/builds/my-jewellery/code/frontend-nuxt3/storefront/pages/shops.vue")
  }
]