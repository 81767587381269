/**
 * Store for managing A/B tests.
 *
 * This store is responsible for setting and retrieving A/B tests from Exponea,
 * keeping track of the tests that are running, and storing them in the state.
 *
 * @returns {Object} The store's state and methods.
 * @returns {Ref<Map<number, 'A' | 'B'>>} tests - Reactive state containing the A/B test results.
 * @returns {Function} getAbTest - Method to retrieve an A/B test and store its result.
 * @returns {Function} setAbTests - Method to set all A/B tests.
 */

interface AbTest {
  enabled: boolean
  locale: 'nl-nl' | 'en' | 'de' | 'fr'
  id: string
  name: string
  number: number
  variants: {
    [key: string]: {
      name: string
      percentage: number
    }
  }
}

export const tests: AbTest[] = [
  {
    enabled: true,
    locale: 'nl-nl',
    id: '679a6210f2cf20f54aa6dc8e',
    name: "A/B - PDP - Slide-over 'Kies een maat' PDP - NL #25001",
    number: 25001,
    variants: {
      v0: { name: 'ab-25001-V0-original-size-selection', percentage: 50 },
      v1: { name: 'ab-25001-V1-slide-over-size-selection', percentage: 50 },
    },
  },
  {
    enabled: false,
    locale: 'nl-nl',
    id: '67926219da77162f1d8a5e69',
    name: 'A/B - POP - Pagination - #24042',
    number: 24042,
    variants: {
      v0: { name: 'ab-24042-V0-load-more-button', percentage: 33 },
      v1: { name: 'ab-24042-V1-load-more-button-pagination', percentage: 33 },
      v2: { name: 'ab-24042-V2-pagination-only', percentage: 33 },
    },
  },
  {
    enabled: true,
    locale: 'nl-nl',
    id: '67bc4b57ea0048220b98dd2c',
    name: 'A/B - All - Header / overlay - Hide search bar below icon - NL #25019',
    number: 25019,
    variants: {
      v0: { name: 'ab-25019-V0-regular-search-bar', percentage: 50 },
      v1: { name: 'ab-25019-V1-hide-search-bar-below-icon', percentage: 50 },
    },
  },
  {
    enabled: true,
    locale: 'nl-nl',
    id: '67a22ebf562b25bfc025d3db',
    name: 'A/B - All - Header / overlay - Hide search bar below icon - NL #25020',
    number: 25020,
    variants: {
      v0: { name: 'ab-25020-V0-desktop-regular-search-bar', percentage: 50 },
      v1: { name: 'ab-25020-V1-desktop-hide-search-bar-below-icon', percentage: 50 },
    },
  },
  {
    enabled: true,
    locale: 'nl-nl',
    id: '67ea730b133f3abe3c7a7d08',
    name: 'A/B - All - Header / overlay - White menu bar - NL #25021',
    number: 25021,
    variants: {
      v0: { name: 'ab-25021-V0-black-menu-bar', percentage: 50 },
      v1: { name: 'ab-25021-V1-white-menu-bar', percentage: 50 },
    },
  },
  {
    enabled: true,
    locale: 'nl-nl',
    id: '62303ee46a462374562971d3',
    name: 'A/B - PDP - Dropdown size selection desktop - NL #25022',
    number: 25022,
    variants: {
      v0: { name: 'ab-25022-V0-no-dropdown-size-selection', percentage: 50 },
      v1: { name: 'ab-25022-V1-dropdown-size-selection', percentage: 50 },
    },
  },
  {
    enabled: true,
    locale: 'nl-nl',
    id: '67e16f4dcd8e65a30d3e76bd',
    name: 'A/B - PDP kleding - Notification for multiple sizes - NL #25023',
    number: 25023,
    variants: {
      v0: { name: 'ab-25023-V0-no-double-sizes-popup', percentage: 50 },
      v1: { name: 'ab-25023-V1-double-sizes-popup', percentage: 50 },
    },
  },
]

/**
 * List of all A/B tests
 * All tests are stored in this array.
 */
const abTests: Ref<AbTest[]> = ref(tests)

declare global {
  interface Window {
    hj: any
    exponea: any
  }
}

/**
 * Shows a toast when an A/B test event is successfully fired to Exponea.
 */
const trackSuccess = ({ number, version, action }: { number: number; version: string; action: string }): void => {
  if (localStorage.getItem('showToastOnExponeaEvents') !== 'true') return
  const toast = useToast()
  toast.add({
    title: `#${number} - ${version.toUpperCase()}`,
    description: action,
    icon: 'i-ph-test-tube',
    duration: 5000,
  })
}

/**
 * Shows a toast when an A/B test event had an error when being fired to Exponea.
 */
const trackFailure = ({ number, version, action }: { number: number; version: string; action: string }) => {
  if (localStorage.getItem('showToastOnExponeaEvents') !== 'true') return
  const toast = useToast()
  toast.add({
    title: `Something went wrong with #${number} - ${version.toUpperCase()}`,
    description: action,
    icon: 'i-ph-test-tube',
    duration: 5000,
    color: 'error',
  })
}

export const useAbTestStore = defineStore('abTest', () => {
  const { waitUntilExponeaCookieIsSet } = useExponeaStore()
  const logger = useAppLogger('useAbTestStore')
  const {
    $i18n: { locale },
  } = useNuxtApp()
  const datadogMonitoringCookie = useCookie('datadogmonitoring').value
  const automatedTestCookie = useCookie('automatedtest').value

  /*
   * Tests are stored in a map where the number is the test number and the version will be v0, v1, etc.
   * So for example: tests.get(24025) will return 'v0' or 'v1'
   */
  const state = reactive({
    tests: new Map<number, string>(),
    eventShownThisPageView: {} as { [key: number]: boolean },
  })

  /**
   * Sets all A/B tests upon initialization of the app
   * They'll get loaded as quick as possible and are stored in the state
   */
  const setAbTests = async () => {
    if (datadogMonitoringCookie || automatedTestCookie) {
      logger.info('Datadog monitoring or automated test cookie present. Setting AB tests stopped')
      return
    }

    await waitUntilExponeaCookieIsSet()

    const route = useRoute()

    // For QA purposes - Store required version in localStorage
    const abTestStorage = JSON.parse(localStorage.getItem('abtests') || '{"qa": {}}')
    if (route.query.abtest && route.query.abversion) {
      const abTest = route.query.abtest as string
      abTestStorage.qa[abTest] = route.query.abversion as string
      localStorage.setItem('abtests', JSON.stringify(abTestStorage))
    }

    // Enable tests that are set in localStorage
    abTests.value = abTests.value.map((test) => {
      if (abTestStorage.qa[test.number]) return { ...test, enabled: true }
      return test
    })

    abTests.value
      .filter((test) => {
        return test.enabled && test.locale === locale.value
      })
      .forEach((test) => {
        getAbTest(test)
      })
  }

  /**
   * Retrieves and processes an A/B test result using the Exponea service.
   *
   * @param {Object} params - Parameters for the A/B test.
   * @param {string} params.id - Unique identifier for the test.
   * @param {number} params.number - Test number. We use this number to retrieve the test from the state.
   * @param {string} params.name - Name of the test.
   * @param {Object} params.variants - Variants of the test.
   * @param {Object} params.variants.v0 - First variant of the test.
   * @param {string} params.variants.v0.name - Name of the first variant.
   * @param {number} params.variants.v0.percentage - Percentage allocation for the first variant.
   * @param {Object} params.variants.v1 - Second variant of the test.
   * @param {string} params.variants.v1.name - Name of the second variant.
   * @param {number} params.variants.v1.percentage - Percentage allocation for the second variant.
   *
   * @returns {void} - But it will store result 'v0', 'v1', etc or 'ControlGroup' in the state.
   */
  const getAbTest = ({ number, name, variants }: AbTest): void => {
    // For QA: if test is set in localStorage, use that version
    const abTestStorage = JSON.parse(localStorage.getItem('abtests') || '{"qa": {}}')
    if (abTestStorage.qa[number]) {
      state.tests.set(number, abTestStorage.qa[number])
      return
    }
    // For regular users: if test is set in localStorage, use that version
    if (abTestStorage[number]) {
      state.tests.set(number, abTestStorage[number])
    }

    window.exponea.getAbTest(
      name,
      {
        ...Object.fromEntries(Object.entries(variants).map(([key, value]) => [value.name, value.percentage])),
        ControlGroup: 0,
      },
      (variant: string) => {
        if (!variant) {
          logger.error('No variant found')
          return
        }

        const version = Object.entries(variants)?.find(([key, value]) => value.name === variant)?.[0] ?? 'v0'
        state.tests.set(number, version)

        abTestStorage[number] = version
        localStorage.setItem('abtests', JSON.stringify(abTestStorage))
      },
    )
  }

  /**
   * Sends an A/B test show event to Exponea and stores the result.
   *
   * @param {number} id - The unique identifier of the A/B test.
   * @param {Ref<HTMLElement | HTMLElement[]>} elements - Vue ref of element or elements that are shown.
   * @param {string} message - The message that is sent as event
   *
   * @returns {Promise<void>} - But it will store result 'v0', 'v1', etc or 'ControlGroup' in the state.
   */
  async function sendAbShowEvent(
    id: number,
    elements: Ref<HTMLElement | HTMLElement[]>,
    message: string = 'show',
  ): Promise<void> {
    if (state.eventShownThisPageView[id]) return
    const elementArray = Array.isArray(elements) ? elements : [elements]
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            sendAbTestEvent(id, message)
            state.eventShownThisPageView[id] = true
            observer.disconnect() // Disconnect if any element is visible
          }
        })
      },
      { threshold: 1.0, rootMargin: '0px' },
    )

    const router = useRouter()
    router.beforeEach(() => {
      observer.disconnect()
      state.eventShownThisPageView[id] = false
    })

    elementArray.forEach((element) => observer.observe(element.value))
  }

  /**
   * Sends an A/B test event to Exponea.
   *
   * @param {number} id - The unique identifier of the A/B test.
   * @param {string} action - The action performed (e.g., 'show', 'click').
   */
  const sendAbTestEvent = (id: number, action: string): void => {
    const test = abTests.value.find((test) => test.number === id)

    if (test?.enabled === false || test?.locale !== locale.value) {
      return
    }

    const abVersion = state.tests.get(id)
    if (!abVersion) {
      logger.error(`Test with id ${id} not found in state`)
      return
    }

    if (!test) {
      logger.error(`Version ${abVersion} not found tests`)
      return
    }

    const variantName = test.variants[abVersion].name

    const exponeaData = {
      action,
      banner_id: test.id,
      banner_name: test.name,
      banner_type: 'banner',
      variant_id: variantName.replaceAll(' ', '_').toLowerCase(),
      variant_name: variantName,
      interaction: false,
      location: window.location.href,
      path: window.location.pathname,
      variant_click: '',
    }

    if (action === 'show' && window?.hj) {
      window.hj('tagRecording', [variantName])
      window.hj('trigger', variantName)
    }

    window.exponea.track(
      'banner',
      exponeaData,
      () => trackSuccess({ number: test.number, version: abVersion, action }),
      () => trackFailure({ number: test.number, version: abVersion, action }),
    )
  }

  return {
    ...toRefs(state),
    setAbTests,
    sendAbTestEvent,
    sendAbShowEvent,
  }
})

export default useAbTestStore
