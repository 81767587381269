import mask from '~/utils/mask'
import { useCart, useUiNotification } from '~/composables'
import { generateUserData } from './helpers/generateUserData'
import type {
  Customer,
  CustomerCreateInput,
  RequestPasswordResetEmailMutationVariables,
  ResetPasswordMutationVariables,
} from '@vue-storefront/magento-types'
import type { UseUserInterface, UseUserUpdateUserParams, UseUserChangePasswordParams, UseUserErrors } from './useUser'
import {
  cartCookieName,
  customerCookieName,
  loginEmailCookieName,
  loginRedirectCookieName,
  phpSessionIdCookieName,
} from '~/enums/cookieNameEnum'
import { handleError } from '~/utils/errorHandler'
import type { Ref } from 'vue'

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export const useUser = (): UseUserInterface => {
  const logger = useAppLogger('useUser')
  const { $i18n, $magento } = useNuxtApp()
  const { t, locale } = $i18n
  const customerStore = useCustomerStore()
  const { cartLoaded } = storeToRefs(useCartStore())
  const router = useRouter()
  const { setCart } = useCart()
  const { setWishlist } = useWishlistStore()
  const { send: sendNotification } = useUiNotification()
  const loading: Ref<boolean> = ref(false)

  const errorState = reactive<UseUserErrors>({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  })

  const cartCookie = useCookie(cartCookieName)
  const customerCookie = useCookie(customerCookieName)

  const setUser = (newUser: Customer) => {
    customerStore.setUser(newUser)
    logger.debug('useUserFactory.setUser', newUser)
  }

  const resetErrorValue = () => {
    errorState.updateUser = null
    errorState.register = null
    errorState.login = null
    errorState.logout = null
    errorState.changePassword = null
    errorState.load = null
  }

  const updateCustomerEmail = async (credentials: { email: string; password: string }): Promise<void> => {
    const { errors } = await $magento.updateCustomerEmail(credentials)

    if (errors) {
      throw errors.map((e) => e.message).join(',')
    }
  }

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser }: UseUserUpdateUserParams) => {
    logger.debug('[Magento] Update user information', { providedUser })
    resetErrorValue()

    const { email: oldEmail } = customerStore.user
    const { email, password, ...updateData } = providedUser

    const userData = generateUserData(updateData)

    if (email && email !== oldEmail) {
      await updateCustomerEmail({
        email,
        password,
      })
    }

    const { data, errors } = await $magento.updateCustomer(userData)
    logger.debug('[Result]:', { data })
    console.log({ data, errors })

    if (errors?.length) {
      const allErrorMessages = errors.map((e) => e.message).join(',')
      logger.error('useUser/updateUser', allErrorMessages)
      errorState.updateUser = allErrorMessages
      throw new Error(allErrorMessages)
    }

    customerStore.user = data?.updateCustomerV2?.customer || {}
    errorState.updateUser = null
  }

  const logout = async () => {
    logger.debug('[Magento] useUserFactory.logout')
    resetErrorValue()

    try {
      await $magento.revokeCustomerToken()

      setCart(null)
      setWishlist(null)
      customerStore.setIsLoggedIn(false)
      customerStore.setUser(null)
      cartCookie.value = null
      customerCookie.value = null
      errorState.logout = null
      window.exponea?.anonymize()
    } catch (error: unknown) {
      const err = handleError(error)
      errorState.logout = err.message
      logger.error('useUser/logout', err)
    }
  }

  const load = async () => {
    logger.debug('[Magento] useUser.load')
    resetErrorValue()

    try {
      loading.value = true

      if (!customerCookie.value) {
        customerStore.setIsLoggedIn(false)
        customerStore.setUser(null)
        return null
      }
      try {
        const { data } = await $magento.customer()

        logger.debug('[Result]:', { data })

        customerStore.setIsLoggedIn(true)
        customerStore.setUser(data?.customer ?? {})
        //Identify the loged in user and update segmentation cookie
        window.exponea?.identify(data?.customer?.email)
      } catch {
        await logout()
      }
      errorState.load = null
    } catch (error: unknown) {
      const err = handleError(error)
      errorState.load = err.message
      logger.error('useUser/load', err)
    } finally {
      loading.value = false
    }

    return customerStore.user
  }

  const login = async (email: string, password: string, rememberMe: boolean) => {
    logger.debug('[Magento] useUser.login')
    resetErrorValue()

    try {
      loading.value = true

      const { data, errors } = await $magento.generateCustomerToken({
        email,
        password,
      })

      logger.debug('[Result]:', { data })

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',')
        logger.error(joinedErrors)
        throw new Error(joinedErrors)
      }

      customerStore.setIsLoggedIn(true)

      // For some reason the cookie doesn't update in time for $magento.customerCart() if we use useCookie
      document.cookie = `${customerCookieName}=${data?.generateCustomerToken?.token}; max-age=2592000; path=/`
      // Because the cookie domain has a "." prefix, Nuxt can't seem to be able to handle it with useCookie
      document.cookie = `${phpSessionIdCookieName}=; Max-Age=0`

      // merge existing cart with customer cart
      // todo: move this logic to separate method
      const cartId = cartCookie.value
      const currentCartId = cartId?.indexOf('|') ? cartId.split('|').pop() : cartId
      const cart = await $magento.customerCart()
      cartLoaded.value = true
      const newCartId = cart.data.customerCart.id

      try {
        if (newCartId && currentCartId && currentCartId !== newCartId) {
          const { data: dataMergeCart } = await $magento.mergeCarts({
            sourceCartId: currentCartId,
            destinationCartId: newCartId,
          })

          setCart(dataMergeCart.mergeCarts)
          cartCookie.value = `${dataMergeCart.mergeCarts.total_quantity}|${dataMergeCart.mergeCarts.id}`
        } else {
          setCart(cart.data.customerCart)
          cartCookie.value = `${cart.data.customerCart.total_quantity}|${cart.data.customerCart.id}`
        }
      } catch {
        // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
        // It can be removed when Magento 2.4.5 will be release
        setCart(cart.data.customerCart)
      }
      errorState.login = null
      load()

      if (rememberMe) {
        const emailCookie = useCookie(loginEmailCookieName, { maxAge: 30 * 24 * 60 * 60 })
        emailCookie.value = email
      }
      const loginRedirectUrl = useCookie(loginRedirectCookieName)
      const relativeLoginRedirectUrl = loginRedirectUrl.value?.replace(location.origin, '')

      router.push(relativeLoginRedirectUrl ?? `/${locale.value}/customer/account`)
    } catch (error: unknown) {
      const err = handleError(error)
      errorState.login = err.message
      logger.error('useUser/login', err)
    } finally {
      loading.value = false
    }
  }

  const register = async ({ email, password, recaptchaToken, ...baseData }: CustomerCreateInput) => {
    logger.debug('[Magento] useUser.register')
    resetErrorValue()

    try {
      loading.value = true

      const { data } = await $magento.createCustomerV2({
        input: {
          email,
          password,
          recaptchaToken,
          ...baseData,
        },
      })

      logger.debug('[Result]:', { data })

      errorState.register = null

      const customer = data?.createCustomerV2?.customer

      if (customer?.id) {
        await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: t('You must confirm your account. Please check your email for the confirmation link.') as string,
            persist: true,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check',
          })

          resolve()
        })
        return
      }

      await login(email, password)
    } catch (error: unknown) {
      const err = handleError(error)
      errorState.register = err.message
      logger.error('useUser/register', err)
    } finally {
      loading.value = false
      //this will be the flow for V2 once we have the email confirmation
      //router.push(accountUrl.value)
    }
  }

  const requestPasswordResetEmail = async (params: RequestPasswordResetEmailMutationVariables) => {
    const { data } = await $magento.requestPasswordResetEmail(params)
    return data
  }

  const resetPassword = async (params: ResetPasswordMutationVariables) => {
    const { data, errors } = await $magento.MJresetPassword(params)
    return { ...data, errors }
  }

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    logger.debug('[Magento] useUser.changePassword', {
      currentPassword: mask(params.current),
      newPassword: mask(params.new),
    })
    resetErrorValue()

    try {
      loading.value = true

      const { data, errors } = await $magento.changeCustomerPassword({
        currentPassword: params.current,
        newPassword: params.new,
      })

      let joinedErrors = null

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',')
        logger.error(joinedErrors)
        throw new Error(joinedErrors)
      }

      logger.debug('[Result] ', { data })

      customerStore.setUser(data?.changeCustomerPassword)
      errorState.changePassword = joinedErrors
    } catch (error: unknown) {
      const err = handleError(error)
      throw new Error(err.message)
    } finally {
      loading.value = false
    }
  }

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    requestPasswordResetEmail,
    resetPassword,
    loading: readonly(loading),
    error: errorState,
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
  }
}

export default useUser
export * from './useUser'
